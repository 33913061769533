const onPageOpen = () => {
  document.querySelectorAll('.th-page-cart-button').forEach((el) => {
    el.classList.add('th-page-cart-button_hidden');
  });
  document.querySelectorAll('.th-page-close-button').forEach((el) => {
    el.classList.remove('th-page-close-button_hidden');
  });
};

const onPageClose = () => {
  document.querySelectorAll('.th-page-cart-button').forEach((el) => {
    el.classList.remove('th-page-cart-button_hidden');
  });
  document.querySelectorAll('.th-page-close-button').forEach((el) => {
    el.classList.add('th-page-close-button_hidden');
  });
};

const setCloseButtonPhrase = () => {
  const rootElement = document.querySelector('.th-app');

  if (!rootElement) {
    return false;
  }

  const phrase = rootElement.dataset.closeButtonPhrase;
  const styleElem = document.head.appendChild(document.createElement('style'));

  styleElem.innerHTML = `.th-modal__close-button::before, .th-modal__close-button::after, .th-dialog__close-btn::before, .th-dialog__close-btn::after {content: '${phrase}' !important;}`;
};

const init = () => {
  window.addEventListener('th:pageOpen', onPageOpen);
  window.addEventListener('th:pageClose', onPageClose);
  setCloseButtonPhrase();
};

init();